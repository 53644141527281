import React, { useCallback} from "react"
import { useDispatch  } from "react-redux"
import { Button, Stack } from "@mui/material"
import {
	PSE_STATUS,
	updateProductionStepExecutionStatusToDone,
	showProductionStepsSupervision
} from "../../actions/ProductionSteps/ProductionStepExecutions"
import ProductionStepExecutionFooter from "./ProductionStepExecutionFooter"
import PSEResume from "./preview/PSEResume"
import PSEProgressBar from "./preview/PSEProgressBar"
import { StyledMainContainer, StyledSubStatusTitle } from "./styledComponents"
import CommentsAlert from "./preview/PSECommentsAlert"
import PSEPreparation from "./preview/PSEPreparation"
import ProductionStepsExecutionFixedHeader from "./ProductionSteoExecutionsFixedHeader"

const ProductionStepExecutionToTest = ({
	productionStepExecution,
	onClickCommentsIcon,
	commentsCount
}) => {

	const dispatch = useDispatch()

	const handleBack = () => dispatch(showProductionStepsSupervision())


	const handleTestingConfirm = useCallback(() => {
		dispatch(updateProductionStepExecutionStatusToDone(productionStepExecution.objectId, { status: PSE_STATUS.done }))
	}, [productionStepExecution])

	return (
		<>
			{/* header */}
			<ProductionStepsExecutionFixedHeader onBack={handleBack} />

			{/* body */}
			<StyledMainContainer>
				<Stack gap={3} sx={{ p: 3, borderBottom: "1px solid #E0E0E0"}}>
					<PSEResume
						name={productionStepExecution.productionStepSnapshot.name}
						status={productionStepExecution.status}
						transformation={productionStepExecution.productionStepSnapshot.transformation}
						section={productionStepExecution.sectionName}
						recipe={productionStepExecution.recipeName}
						uniqueCode={productionStepExecution.uniqueCode}
						onClickComments={onClickCommentsIcon}
						commentsCount={commentsCount}
						// merged
						isStepMerged={productionStepExecution.isStepMerged}
						recipes={productionStepExecution.mergedProductionStepExecutions}
					/>
					<PSEProgressBar
						currentSubStatus={productionStepExecution.subStatus}
						productionStepExecution={productionStepExecution}
						isDone={[PSE_STATUS.toTest, PSE_STATUS.done].includes(productionStepExecution.status)}
						times={{
							startTime: productionStepExecution.startTime,
							endTime: productionStepExecution.endTime,
							theoreticalStartTime: productionStepExecution.theoreticalStartTime,
							theoreticalEndTime: productionStepExecution.theoreticalEndTime
						}}
					/>
				</Stack>
					<Stack gap={2} sx={{ p: 3 }}>
						<StyledSubStatusTitle>Tester la préparation.</StyledSubStatusTitle>
						<PSEPreparation
							productionStepExecution={productionStepExecution}
							isEnd
						/>
						<CommentsAlert>Vous pouvez ajouter un commentaire (goût, texture etc.) si besoin.</CommentsAlert>
					</Stack>
			</StyledMainContainer>

			{/* footer */}
			<ProductionStepExecutionFooter
				rightAction={
					<Button variant="contained" onClick={handleTestingConfirm}>
						Valider la préparation
					</Button>
				}
			/>
		</>
	)
}

export default ProductionStepExecutionToTest